import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h5>{`Know more about me!`}</h5>
    <p>{`Hello, I am Rajesh Bhusal from one of the districts of Nepal i.e, Gulmi. I am the lover of tech who likes to learn about new technology.Currently, I know few programming languages like C, Python, Javascript etc and some of styling and Markup languages like CSS, Bootstrap and HTML. I am currently studying Science at Tilottama campus at Butwal in Nepal.This site is created using the library of Javascript called React Js which was developed by Facebook.`}</p>
    <p>{`I would appreciate you!!!! If you follow me on Insta and Facebook. Links are given below!!!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      