import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact Me`}</h2>
    <p>{`I would appreciate you!!!! If you follow me on Insta and Facebook. Links are given below!!!`}</p>
    <p>{`Do follow me on `}<a parentName="p" {...{
        "href": "https://instagram.com/rjeshbh"
      }}>{`Instagram`}</a>{` and `}<a parentName="p" {...{
        "href": "https://facebook.com/bhusalrajess"
      }}>{`Facebook`}</a>{`
or
Mail me at `}<a parentName="p" {...{
        "href": "mailto:tipstricks118@gmail.com"
      }}>{`tipstricks118@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      